import React, {createContext, useEffect, useState} from "react";
import {auth} from "../shared-components/Firebase";
import {
    isSignInWithEmailLink,
    signInWithEmailLink,
    sendSignInLinkToEmail,
    setPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
} from "firebase/auth";
// import {language} from "../content/language";
import {Utility} from '../shared-components/Utility';
import enums from '../enums.json';

const {adminPostTypeEnum, postFrequencyEnum} = enums;

const initialSocialMediaArray = [
    ["facebook", {
        isActive: true,
        link: ""
    }],
    ["instagram", {
        isActive: false,
        link: ""
    }],
    ["linkedIn", {
        isActive: false,
        link: ""
    }],
    ["youTube", {
        isActive: false,
        link: ""
    }],
    ["tikTok", {
        isActive: false,
        link: ""
    }],
]

export const AdminContext = createContext(null);

export const AdminProvider = props => {
    const {
        setContentLoading,
        setCurrentUploads,
        setUserComments,
        setUserName,
        clearTempMedia,
        deleteAllUploads,
    } = props;

    const [userDetails, setUserDetails] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [isUserLoaded, setIsUserLoaded] = useState(false);
    const [stayLoggedIn, setStayLoggedIn] = useState(true);
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [adminPostStage, setAdminPostStage] = useState(adminPostTypeEnum.SELECT);
    const [currentPostType, setCurrentPostType] = useState(adminPostTypeEnum.MEDIA);
    const [showUploadNew, setShowUploadNew] = useState(true);
    const [postFrequency, setPostFrequency] = useState(postFrequencyEnum.ONCE_AS_NORMAL_POSTS);
    const [postId, setPostId] = useState(Utility.makeId(20));
    const [socialMediaMap, setSocialMediaMap] = useState(new Map(initialSocialMediaArray));
    const [isEdit, setIsEdit] = useState(false);
    const [likes, setLikes] = useState([]);
    const [uploadLink, setUploadLink] = useState("");

    useEffect(() => {
        const isSiteLinkedToViaSignInWithEmailLink = isSignInWithEmailLink(auth, window.location.href);
        try {
            if (isSiteLinkedToViaSignInWithEmailLink) {
                setContentLoading(true);
                setEmailSubmitted(true);

                let email = localStorage.getItem('emailForSignIn');
                const stayLoggedIn = localStorage.getItem('stayLoggedIn') === "true";

                if (!email) {
                    email = window.prompt("Please provide your email for confirmation");
                }

                signInWithEmailLink(auth, email, window.location.href)
                    .then(() => {
                        localStorage.removeItem('emailForSignIn');
                        setLoginPersistence(stayLoggedIn);
                    })
                    .catch((e) => {
                        console.error(e);
                        setContentLoading(false);
                        setEmailSubmitted(false);
                    });
            } else setEmailSubmitted(false);
        } catch (e) {
            console.error(e);
            setContentLoading(false);
            setEmailSubmitted(false);
            setIsUserLoaded(true);
        }
    }, []);

    useEffect(() => {
        return auth.onAuthStateChanged((firebaseAuthUser) => {
            setContentLoading(true);
            setIsUserLoggedIn(!!firebaseAuthUser);

            if (!!firebaseAuthUser) {
                const {displayName, email, emailVerified, isAnonymous, phoneNumber, photoURL, metadata, uid} = firebaseAuthUser;
                const selectedUserDetails = {
                    displayName,
                    email,
                    emailVerified,
                    isAnonymous,
                    phoneNumber,
                    photoURL,
                    metadata,
                    uid
                };
                setUserDetails(selectedUserDetails);
                Utility.httpCall("getUserByAuth").then(response => {
                    const userDbDetails = response?.data || {};
                    const tempUserDetails = {...selectedUserDetails, ...userDbDetails};
                    setUserDetails(tempUserDetails);
                    setIsUserLoaded(true);
                });
            } else {
                setIsUserLoaded(true);
                setUserDetails(null);
                setEmailSubmitted(false);
            }
            setContentLoading(false);
        });
    }, []);

    useEffect(() => {
        checkExpiration()
    }, []);

    const checkExpiration = () => {
        const expiration = localStorage.getItem("loginExpiration");
        if (expiration) {
            const expirationDate = new Date(expiration);
            const now = new Date();
            if (now > expirationDate) {
                signOut();
                console.log("Session expired, user logged out.");
            }
        }
    };

    const signOut = () => {
        auth.signOut().then(() => {
            setUserDetails(null);
            setIsUserLoggedIn(false);
            localStorage.removeItem("loginExpiration");
        });
    }

    // const updateUserProp = async (propKey, value, updateDb = false) => {
    //     let success = !updateDb;
    //     if (updateDb) {
    //         const response = await Utility.httpCall("updateUser", {
    //             userId: userDetails.userId,
    //             [propKey]: value,
    //         });
    //         success = response?.data;
    //     }
    //
    //     if (success) {
    //         const tempUserDetails = {...userDetails};
    //         tempUserDetails[propKey] = value;
    //         setUserDetails(tempAdminDetails);
    //     }
    // }

    const sendLoginLink = async (eventId, email, redirectUrl) => {
        setContentLoading(true);
        const userIsAdminResponse = await Utility.httpCall("getUserIsEventAdmin", {
            userEmail: email,
            eventId,
        });

        if (!userIsAdminResponse?.data) {
            setContentLoading(false);
            return false;
        }

        const actionCodeSettings = {
            url: redirectUrl,
            handleCodeInApp: true
        }

        try {
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        } catch (e) {
            console.error(e);
            setContentLoading(false);
            return false;
        }

        localStorage.setItem('emailForSignIn', email);
        localStorage.setItem('stayLoggedIn', stayLoggedIn.toString());

        setEmailSubmitted(true);
        setContentLoading(false);
        return true;
    }

    const setLoginPersistence = (stayLoggedIn) => {
        setPersistence(auth, stayLoggedIn ? browserLocalPersistence : browserSessionPersistence)
            .catch((e) => console.error(e));

        if (stayLoggedIn) {
            const expiration = new Date();
            expiration.setDate(expiration.getDate() + 30);
            localStorage.setItem("loginExpiration", expiration.toISOString());
        } else {
            localStorage.removeItem("stayLoggedIn");
        }
    };

    const getUserIsAdminWithEventAccess = (eventId) =>
        !!isUserLoggedIn
        && !!userDetails
        && (
            (userDetails.userType === 9)
            || (userDetails.userType >= 2 && userDetails.eventIds?.includes(eventId))
        )

    const resetSocialMedia = () => {
        const updatedSocialMediaMap = new Map(socialMediaMap);
        socialMediaMap.forEach((ignored, socialMediaName) => {
            updatedSocialMediaMap.set(socialMediaName, {isActive: false, link: ""})
        });
        updatedSocialMediaMap.set("facebook", {isActive: true, link: ""});
        setSocialMediaMap(updatedSocialMediaMap);
    }

    const startOver = (deleteUploads) => {
        if (!!deleteUploads && !isEdit) {
            deleteAllUploads();
        }
        setIsEdit(false);
        setPostId("");
        clearTempMedia();
        setCurrentUploads([]);
        setUserComments("");
        setUserName("");
        setUploadLink("");
        setLikes([]);
        resetSocialMedia();
        setPostFrequency(0);
        setAdminPostStage(adminPostTypeEnum.SELECT);
    }

    const setUpEditPost = (eventPost) => {
        const reloadedCurrentUploads = [];
        let postMediaLink = "";
        eventPost.mediaUrls?.forEach((urlObj) => {
            reloadedCurrentUploads.push({
                tempUrl: urlObj.url,
                downloadUrl: urlObj.url,
                mediaLink: urlObj.mediaLink,
                uploadId: urlObj.mediaId || Utility.makeId(16),
                hasAlreadyBeenFiltered: true,
                isDeleted: false,
            });
            postMediaLink = urlObj.mediaLink;
        });

        setLikes(eventPost.likes || []);
        setIsEdit(true);
        setPostId(Utility.getOriginalEventPostId(eventPost.id));
        setCurrentUploads(reloadedCurrentUploads);
        setUserComments(eventPost.blurb);
        setUserName(eventPost.name);
        setPostFrequency(eventPost.postFrequency || 0);
        setUploadLink(postMediaLink);
        setAdminPostStage(eventPost.postType);
        setSocialMediaMap(eventPost.socialMediaMap || new Map(initialSocialMediaArray));
    }

    return (
        <AdminContext.Provider value={{
            userDetails,
            isUserLoggedIn,
            isUserLoaded,
            stayLoggedIn,
            emailSubmitted,
            adminPostStage,
            currentPostType,
            showUploadNew,
            postFrequency,
            postId,
            socialMediaMap,
            isEdit,
            likes,
            uploadLink,

            setUserDetails,
            setStayLoggedIn,
            setAdminPostStage,
            setEmailSubmitted,
            setCurrentPostType,
            setShowUploadNew,
            setPostFrequency,
            setSocialMediaMap,
            setUploadLink,

            signOut,
            sendLoginLink,
            startOver,
            setUpEditPost,
            getUserIsAdminWithEventAccess,
        }}>
            {props.children}
        </AdminContext.Provider>
    )
}