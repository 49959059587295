import React, {useContext, useEffect} from "react";
import "../../componentStyle.scss";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {AdminContext} from "../../../contexts/AdminContext";
import {language} from "../../../shared-components/content/language";
import {Utility} from "../../../shared-components/Utility";
import StyledUpload from "../../../shared-components/generic/StyledUpload";
import {IconButton} from "@mui/material";
import {DeleteForever} from "@mui/icons-material";
import StyledInput from "../../../shared-components/generic/StyledInput";
import StyledButton from "../../../shared-components/generic/StyledButton";
import StyledSelect from "../../../shared-components/generic/StyledSelect";
import enums from "../../../enums.json";
import {ArrowCounterClockwise} from "@phosphor-icons/react";

const {colors, adminPostTypeEnum, postFrequencyEnum} = enums;

const CreateMediaPost = () => {
    const {
        appLang,
        eventColor,
        currentUploads,
        userComments,
        userName,
        handleDeleteImage,
        setUserComments,
        setUserName,
        setUploadMediaModalOpen,
    } = useContext(GeneralContext);
    const {
        postFrequency,
        uploadLink,
        setPostFrequency,
        setAdminPostStage,
        setCurrentPostType,
        setUploadLink,
        startOver,
    } = useContext(AdminContext);
    const content = language[appLang];
    const uploads = currentUploads?.filter((upload) => !upload.isDeleted) || [];

    useEffect(() => {
        setCurrentPostType(adminPostTypeEnum.MEDIA);
    }, []);

    const renderCurrentUploads = () => {
        const mediaToRender = [];
        uploads.forEach((uploadObj, index) => {
            if (!uploadObj.isDeleted) {
                mediaToRender.push(
                    <div
                        className="media-box"
                        key={`media-upload-${index}`}
                        onClick={() => uploadObj?.mediaLink && window.open(uploadObj.mediaLink, '_blank')}
                    >
                        {Utility.renderMedia(uploadObj.tempUrl, `Uploaded media ${index + 1}`, uploadObj.metaData?.fileExt, "current-upload")}
                        <IconButton
                            style={eventColor ? {"backgroundColor": eventColor, "borderColor": eventColor} : null}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteImage(index, true);
                            }}
                        >
                            <DeleteForever
                                style={eventColor ? {"backgroundColor": eventColor, "borderColor": eventColor} : null}/>
                        </IconButton>
                    </div>
                )
            }
        });

        return mediaToRender;
    }

    const handleInitiateUpload = () => {
        setUploadMediaModalOpen(true);
    }

    return (
        <div className="media-post">
            <div className="view-section">
                <div className="big-text">
                    {Utility.capitalize(content.UPLOAD_MEDIA_PLUS_CAPTION_PLUS_LINK)}
                </div>

                <div className="small-text-regular">
                    {Utility.capitalize(content.UPLOAD_YOUR_IMAGE_OR_VIDEO)}:
                </div>
                <div className="image-gallery">
                    {renderCurrentUploads()}
                    <StyledUpload
                        brandColorOverride={eventColor}
                        onClick={handleInitiateUpload}
                    />
                </div>

                <div className="input-container">
                        <span className="small-text-regular">
                            {Utility.capitalize(content.ADD_A_LINK_FOR_THIS_IMAGE_VIDEO)}:
                        </span>
                    <StyledInput
                        value={uploadLink}
                        onChange={(e) => setUploadLink(e.target.value)}
                        placeholder={content.URL_OPTIONAL}
                    />
                </div>

            </div>

            <div className="section-divider"/>

            <div className="view-section">
                <div className="input-container">
                        <span className="small-text-regular">
                            {Utility.capitalize(content.ADD_YOUR_CAPTION_FOR_THIS_POST)}:
                        </span>
                    <StyledInput
                        value={userComments}
                        onChange={(e) => setUserComments(e.target.value)}
                        placeholder={Utility.capitalize(content.CAPTION_OPTIONAL)}
                    />
                </div>

                <div className="input-container">
                    <span
                        className="small-text-regular">{Utility.capitalize(content.SET_HOW_YOUR_NAME_WILL_BE_DISPLAYED)}:</span>
                    <StyledInput
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        placeholder={Utility.capitalize(content.YOUR_NAME_DEFAULT_EVENT_ORGANIZER)}
                    />
                </div>


                <div className="input-container">
                    <span
                        className="small-text-regular">{Utility.capitalize(content.SET_THE_FREQUENCY_FOR_THIS_POST)}:</span>
                    <StyledSelect
                        brandColorOverride={eventColor}
                        className="additional-text"
                        value={postFrequency}
                        options={Object.keys(postFrequencyEnum).map((postFrequencyKey) => {
                            return {
                                value: postFrequencyEnum[postFrequencyKey],
                                label: Utility.capitalize(content[postFrequencyKey])
                            }
                        })}
                        onChange={(selectedResponse) => setPostFrequency(selectedResponse)}
                        placeholder={Utility.capitalize(content.SELECT_FROM_THE_LIST)}
                    />
                </div>

                <div className="button-section">
                    <StyledButton
                        brandColorOverride={eventColor}
                        type="main"
                        onClick={() => setAdminPostStage(adminPostTypeEnum.PREVIEW)}
                        isDisabled={uploads.length < 1}
                    >
                        {Utility.capitalize(content.PREVIEW)}
                    </StyledButton>

                    <StyledButton
                        brandColorOverride={eventColor}
                        type="secondary"
                        onClick={() => startOver(true)}
                    >
                        <ArrowCounterClockwise color={eventColor || colors.BASE_RED} weight="bold"
                                               size={18}/> {Utility.capitalize(content.START_OVER)}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export default CreateMediaPost;