import React, {useContext, useEffect} from "react";
import "../../componentStyle.scss";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {AdminContext} from "../../../contexts/AdminContext";
import {language} from "../../../shared-components/content/language";
import {Utility} from "../../../shared-components/Utility";
import StyledInput from "../../../shared-components/generic/StyledInput";
import StyledSelect from "../../../shared-components/generic/StyledSelect";
import StyledButton from "../../../shared-components/generic/StyledButton";
import {ArrowCounterClockwise} from "@phosphor-icons/react";
import enums from "../../../enums.json";

const {colors, adminPostTypeEnum, postFrequencyEnum} = enums;

const CreateAttendeeInputPost = () => {
    const {
        appLang,
        eventColor,
        userComments,
        setUserComments,
    } = useContext(GeneralContext);
    const {
        postFrequency,
        setPostFrequency,
        setAdminPostStage,
        setCurrentPostType,
        startOver,
    } = useContext(AdminContext);
    const content = language[appLang];

    useEffect(() => {
        setCurrentPostType(adminPostTypeEnum.ATTENDEE_INPUT);
    }, []);

    return (
        <div className="media-post">
            <div className="view-section">
                <div className="big-text">
                    {Utility.capitalize(content.ATTENDEE_INPUT_COLLECTION)}
                </div>

                <div className="input-container">
                <span className="small-text-regular">
                    {Utility.capitalize(content.ADD_YOUR_CAPTION_FOR_THIS_POST)}:
                </span>
                    <StyledInput
                        value={userComments}
                        onChange={(e) => setUserComments(e.target.value)}
                        placeholder={Utility.capitalize(content.CAPTION_OPTIONAL)}
                    />
                </div>

                <div className="input-container">
                <span className="small-text-regular">
                    {Utility.capitalize(content.SET_THE_FREQUENCY_FOR_THIS_POST)}:
                </span>
                    <StyledSelect
                        brandColorOverride={eventColor}
                        className="additional-text"
                        value={postFrequency}
                        options={Object.keys(postFrequencyEnum).map((postFrequencyKey) => {
                            return {
                                value: postFrequencyEnum[postFrequencyKey],
                                label: Utility.capitalize(content[postFrequencyKey])
                            }
                        })}
                        onChange={(selectedResponse) => setPostFrequency(selectedResponse)}
                        placeholder={Utility.capitalize(content.SELECT_FROM_THE_LIST)}
                    />
                </div>

                <div className="button-section">
                    <StyledButton
                        brandColorOverride={eventColor}
                        type="main"
                        onClick={() => setAdminPostStage(adminPostTypeEnum.PREVIEW)}
                        isDisabled={!userComments}
                    >
                        {Utility.capitalize(content.PREVIEW)}
                    </StyledButton>

                    <StyledButton
                        brandColorOverride={eventColor}
                        type="secondary"
                        onClick={() => startOver(true)}
                    >
                        <ArrowCounterClockwise color={eventColor || colors.BASE_RED} weight="bold" size={18}/>
                        {Utility.capitalize(content.START_OVER)}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export default CreateAttendeeInputPost;