import React, {useContext, useEffect, useState} from "react";
import "../componentStyle.scss";
import {routes, GeneralContext} from "../../contexts/GeneralContext";
import {AdminContext} from "../../contexts/AdminContext";
import {language} from "../../shared-components/content/language";
import StyledButton from "../../shared-components/generic/StyledButton";
import {Utility} from "../../shared-components/Utility";
import {Link, useNavigate} from 'react-router-dom';
import StyledInput from "../../shared-components/generic/StyledInput";
import StyledCheckbox from "../../shared-components/generic/StyledCheckbox";

const AdminLogin = () => {
    const {
        appLang,
        eventId,
        eventColor,
        hasAcceptedTerms,
        setHasAcceptedTerms,
        setErrorMsg,
    } = useContext(GeneralContext);
    const {
        isUserLoggedIn,
        userDetails,
        stayLoggedIn,
        emailSubmitted,
        setStayLoggedIn,
        setEmailSubmitted,
        sendLoginLink,
        getUserIsAdminWithEventAccess
    } = useContext(AdminContext);
    const content = language[appLang];
    const navigate = useNavigate();

    const [loginEmail, setLoginEmail] = useState("");

    useEffect(() => {
        if (!loginEmail) {
            setEmailSubmitted(false);
        }
    }, [loginEmail]);

    useEffect(() => {
        if (getUserIsAdminWithEventAccess(eventId)) {
            navigate(routes.ADMIN_POST + "/?id=" + eventId)
        }
    }, [isUserLoggedIn, userDetails]);

    const verifyAndSendLoginLink = async () => {
        const currentUrl = window.location.href;
        const signInRedirectUrl = currentUrl.replace(routes.ADMIN_LOGIN, routes.SIGN_IN_REDIRECT);
        const success = await sendLoginLink(eventId, loginEmail, signInRedirectUrl);
        if (!success) {
            setErrorMsg(Utility.capitalize(content.THERE_WAS_A_PROBLEM_SENDING_A_LOGIN_LINK_SENTENCE))
        }
    }

    const getTermsAndConditionsLabel = () => {
        const variableMap = new Map();
        const createTermsAndConditionsLink = () => {
            return (
                <Link
                    className="styled-link"
                    to={routes.TERMS_AND_CONDITIONS + "/?id=" + eventId}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span>{content.TERMS_AND_CONDITIONS}</span>
                    <div className="link-underline"/>
                </Link>
            )
        };
        variableMap.set("termsAndConditions", createTermsAndConditionsLink());
        return Utility.createVariableSentenceSpan(Utility.capitalize(content.I_ACCEPT_THE_$$TERMS_AND_CONDITIONS$$), variableMap);
    }

    const renderEmailSent = () => {
        return (
            <>
                <div className="big-text">
                    {Utility.capitalize(content.VERIFICATION_EMAIL_SENT)}
                </div>
                <div className="small-text check-email">
                    {Utility.createVariableSentenceSpan(Utility.capitalize(content.CHECK_YOUR_EMAIL_$$LOGIN_EMAIL$$_SENTENCE), new Map([["loginEmail", loginEmail]]))}
                </div>
                <div className="bottom-section">
                    <div className="small-text">
                        {Utility.capitalize(content.DIDNT_RECEIVE_THE_EMAIL)}
                    </div>
                    <StyledButton
                        type="text"
                        brandColorOverride={eventColor}
                        onClick={verifyAndSendLoginLink}
                    >
                        {Utility.capitalize(content.SEND_IT_AGAIN)}
                    </StyledButton>
                    <StyledButton
                        type="text"
                        brandColorOverride={eventColor}
                        onClick={() => navigate(routes.ROOT + "?id=" + eventId)}
                    >
                        {Utility.capitalize(content.GO_BACK)}
                    </StyledButton>
                </div>
            </>
        )
    }

    const renderLogin = () => {
        return (
            <>
                <div className="big-text">
                    {Utility.capitalize(content.FOR_ADMIN_ACCESS_SENTENCE)}
                </div>
                <div className="input-container">
                    <span className="small-text-regular">
                        {Utility.capitalize(content.YOUR_EMAIL_ADDRESS)}
                    </span>
                    <StyledInput
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                    />
                </div>
                <div className="bottom-section">
                    <StyledCheckbox
                        brandColorOverride={eventColor}
                        className="additional-text"
                        checked={stayLoggedIn}
                        onChange={() => setStayLoggedIn(!stayLoggedIn)}
                        label={Utility.capitalize(content.REMEMBER_ME_ON_THIS_DEVICE_FOR_30_DAYS)}
                    />
                    <StyledCheckbox
                        brandColorOverride={eventColor}
                        className="additional-text"
                        checked={hasAcceptedTerms}
                        onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
                        label={getTermsAndConditionsLabel()}
                    />
                    <StyledButton
                        className="submit-button"
                        type="main"
                        brandColorOverride={hasAcceptedTerms ? eventColor : ""}
                        onClick={verifyAndSendLoginLink}
                        isDisabled={!hasAcceptedTerms || !Utility.validateEmail(loginEmail)}
                    >
                        {Utility.capitalize(content.SUBMIT)}
                    </StyledButton>
                    <StyledButton
                        type="text"
                        brandColorOverride={eventColor}
                        onClick={() => navigate(routes.ROOT + "?id=" + eventId)}
                    >
                        {Utility.capitalize(content.GO_BACK)}
                    </StyledButton>
                </div>
            </>
        )
    }

    return (
        <div className="main-container admin-login">
            {emailSubmitted
                ? renderEmailSent()
                : renderLogin()
            }
        </div>
    );
};

export default AdminLogin;