import React, {useContext, useState} from "react";
import "../componentStyle.scss";
import {GeneralContext, routes} from "../../contexts/GeneralContext";
import {Utility} from "../../shared-components/Utility";
import {language} from "../../shared-components/content/language";
import StyledInput from "../../shared-components/generic/StyledInput";
import {DotsThree, Pencil, Trash} from "@phosphor-icons/react";
import {AdminContext} from "../../contexts/AdminContext";
import StyledDropdownMenu from "../../shared-components/generic/StyledDropdownMenu";
import {useNavigate} from "react-router-dom";
import enums from "../../enums.json";

const {colors} = enums;

const ShareEmailPost = (props) => {
    const {
        postId,
        blurb,
        isAdminPreview,
        postType,
        postFrequency,
    } = props;
    const {
        appLang,
        eventId,
        eventColor,
        deletePost,
        userEmail,
        setUserEmail,
        setBlockFetchPosts,
        saveUserEmail,
    } = useContext(GeneralContext);
    const {
        setUpEditPost,
        getUserIsAdminWithEventAccess
    } = useContext(AdminContext);
    const content = language[appLang];
    const navigate = useNavigate();
    const isAdminWithEditPrivileges = getUserIsAdminWithEventAccess(eventId);

    const [timer, setTimer] = useState(null);
    const [hasSubmittedInput, setHasSubmittedInput] = useState(false);
    const [emailValid, setEmailValid] = useState(true);


    const handleEmailInputChange = (e) => {
        const updatedEmail = e.target.value;
        setUserEmail(updatedEmail);
        if (!updatedEmail) {
            setHasSubmittedInput(false);
        }
        setEmailValid(Utility.validateEmail(updatedEmail));

        if (timer) clearTimeout(timer);

        if (e.key !== "Enter") {
            const newTimer = setTimeout(() => {
                setBlockFetchPosts(false);
                setHasSubmittedInput(true);
                if (!isAdminPreview && Utility.validateEmail(updatedEmail)) {
                    saveUserEmail(updatedEmail);
                }
            }, 5000);
            setTimer(newTimer);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (timer) clearTimeout(timer);

            setBlockFetchPosts(false);
            setHasSubmittedInput(true);

            if (Utility.validateEmail(userEmail)) {
                saveUserEmail(userEmail);
            }
        } else setBlockFetchPosts(true);
    };

    const handleGoToEditPost = () => {
        if (!isAdminWithEditPrivileges) {
            return;
        }

        setUserEmail("");
        setUpEditPost({
            id: postId,
            blurb,
            postFrequency,
            postType,
        });
        navigate(routes.ADMIN_POST + "/?id=" + eventId);
    }

    const handleDeletePost = () => {
        setUserEmail("");
        deletePost(postId);
    }

    const getMenuOptions = () => {
        if (isAdminWithEditPrivileges && !isAdminPreview) {
            return [
                {
                    element: <div className="event-post-menu-option">
                        <Pencil size={18} weight="bold" color={colors.BLACK}/>
                        <span>{Utility.capitalize(content.EDIT)}</span>
                    </div>,
                    action: () => handleGoToEditPost()
                },
                {
                    element: <div className="event-post-menu-option">
                        <Trash size={18} weight="bold" color={colors.ERROR_COLOR}/>
                        <span>{Utility.capitalize(content.DELETE)}</span>
                    </div>,
                    action: handleDeletePost,
                    targetedClassName: "delete"
                }
            ]
        } else return [];
    }

    return (
        <div className="event-post additional-text">
            <div className="post-header">
                <div>{blurb || Utility.capitalize(content.PLEASE_PROVIDE_YOUR_EMAIL_SENTENCE)}</div>
                <div className="post-header-right">
                    <div/>
                    {!isAdminPreview && getMenuOptions()?.length
                        ? <StyledDropdownMenu
                            className="event-post-menu"
                            icon={<DotsThree color="#8E8E8E" weight="bold" size={24}/>}
                            position="left"
                            options={getMenuOptions()}
                            colors={colors}
                            brandColorOverride={eventColor}
                        />
                        : null
                    }
                </div>
            </div>
            <StyledInput
                placeholder={Utility.capitalize(content.YOUR_EMAIL_OPTIONAL)}
                value={userEmail}
                onChange={handleEmailInputChange}
                onKeyDown={handleKeyDown}
                hasError={!!hasSubmittedInput && (!!userEmail && !emailValid)}
                errorMessage={Utility.capitalize(content.VALID_EMAIL_ADDRESS_REQUIRED)}
            />
        </div>
    );
};

export default ShareEmailPost;