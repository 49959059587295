import React, {useContext, useEffect} from "react";
import "../componentStyle.scss";
import {routes, GeneralContext} from "../../contexts/GeneralContext";
import {AdminContext} from "../../contexts/AdminContext";
import {language} from "../../shared-components/content/language";
import StyledButton from "../../shared-components/generic/StyledButton";
import {Utility} from "../../shared-components/Utility";
import {useNavigate} from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import enums from "../../enums.json";

const {colors, adminPostTypeEnum} = enums;

const SignInRedirect = () => {
    const {
        appLang,
        eventId,
        eventColor,
    } = useContext(GeneralContext);
    const {
        isUserLoggedIn,
        setAdminPostStage,
        userDetails,
        getUserIsAdminWithEventAccess
    } = useContext(AdminContext);
    const content = language[appLang];
    const navigate = useNavigate();

    useEffect(() => {
        if (getUserIsAdminWithEventAccess(eventId)) {
            setAdminPostStage(adminPostTypeEnum.SELECT);
            navigate(routes.ADMIN_POST + "/?id=" + eventId);
        }
    }, [isUserLoggedIn, userDetails]);

    return (
        <div className="main-container admin-login">
            <div className="big-text">
                {Utility.capitalize(content.VERIFYING_SIGN_IN_SENTENCE)}
            </div>
            <div className="spinner">
                <ClipLoader
                    loading={true}
                    size={100}
                    color={eventColor || colors.BASE_RED}
                />
            </div>
            <div className="bottom-section">
                <StyledButton
                    type="text"
                    brandColorOverride={eventColor}
                    onClick={() => navigate(routes.ROOT + "?id=" + eventId)}
                >
                    {Utility.capitalize(content.GO_BACK)}
                </StyledButton>
            </div>
        </div>
    );
};

export default SignInRedirect;