import React, {useContext, useEffect} from "react";
import "../../componentStyle.scss";
import {GeneralContext} from "../../../contexts/GeneralContext";
import {AdminContext} from "../../../contexts/AdminContext";
import {language} from "../../../shared-components/content/language";
import {Utility} from "../../../shared-components/Utility";
import StyledInput from "../../../shared-components/generic/StyledInput";
import StyledButton from "../../../shared-components/generic/StyledButton";
import StyledSelect from "../../../shared-components/generic/StyledSelect";
import {ArrowCounterClockwise} from "@phosphor-icons/react";
import enums from "../../../enums.json";
import StyledCheckbox from "../../../shared-components/generic/StyledCheckbox";

const {colors, adminPostTypeEnum, postFrequencyEnum} = enums;

const CreateSocialMediaPost = () => {
    const {
        appLang,
        eventColor,
        userComments,
        setUserComments,
    } = useContext(GeneralContext);
    const {
        postFrequency,
        socialMediaMap,
        setPostFrequency,
        setAdminPostStage,
        setCurrentPostType,
        setSocialMediaMap,
        startOver,
    } = useContext(AdminContext);
    const content = language[appLang];

    const validateSocialMediaInputs = () => {
        let inputsValid = true;
        let selectedNum = 0;
        socialMediaMap.forEach((socialMediaObj) => {
            if (socialMediaObj.isActive) {
                selectedNum += 1;
                if (!socialMediaObj.link || !Utility.validateWebAddress(socialMediaObj.link)) {
                    inputsValid = false;
                }
            }
        });

        return inputsValid && (selectedNum > 0);
    }

    useEffect(() => {
        setCurrentPostType(adminPostTypeEnum.SOCIAL_MEDIA);
    }, []);

    const updateSocialMedia = (socialMediaName, objKey, value) => {
        const updatedSocialMediaMap = new Map(socialMediaMap);
        const socialMediaObj = updatedSocialMediaMap.get(socialMediaName);
        socialMediaObj[objKey] = value;
        updatedSocialMediaMap.set(socialMediaName, socialMediaObj);
        setSocialMediaMap(updatedSocialMediaMap);
    }

    const constructSocialMediaLinkPlaceholder = (socialMediaName) => {
        return Utility.createVariableSentenceString(Utility.capitalize(content.URL_TO_YOUR_$$SOCIAL_MEDIA_NAME$$_PAGE), new Map([["socialMediaName", socialMediaName]]))
    }

    const renderSocialMediaInputs = () => {
        const socialMediaInputs = [];
        socialMediaMap.forEach((socialMediaObj, socialMediaName) => {
            socialMediaInputs.push(
                <div className="input-container" key={socialMediaName}>
                    <StyledCheckbox
                        brandColorOverride={eventColor}
                        className="small-text-regular"
                        checked={socialMediaObj.isActive}
                        onChange={() => updateSocialMedia(socialMediaName, "isActive", !socialMediaObj.isActive)}
                        label={Utility.capitalize(socialMediaName)}
                    />
                    <StyledInput
                        className="social-media-link-input"
                        value={socialMediaObj.link || ""}
                        onChange={(event) => updateSocialMedia(socialMediaName, "link", event.target.value)}
                        placeholder={constructSocialMediaLinkPlaceholder(Utility.capitalize(socialMediaName))}
                    />
                </div>
            );
        });
        return socialMediaInputs;
    }

    return (
        <div className="media-post">
            <div className="view-section">
                <div className="big-text">
                    {Utility.capitalize(content.SOCIAL_MEDIA_LINKS)}
                </div>

                {renderSocialMediaInputs()}

                <div className="input-container">
                <span className="small-text-regular">
                    {Utility.capitalize(content.ADD_YOUR_CAPTION_FOR_THIS_POST)}:
                </span>
                    <StyledInput
                        value={userComments}
                        onChange={(e) => setUserComments(e.target.value)}
                        placeholder={Utility.capitalize(content.CAPTION_OPTIONAL)}
                    />
                </div>


                <div className="input-container">
                <span className="small-text-regular">
                    {Utility.capitalize(content.SET_THE_FREQUENCY_FOR_THIS_POST)}:
                </span>
                    <StyledSelect
                        brandColorOverride={eventColor}
                        className="additional-text"
                        value={postFrequency}
                        options={Object.keys(postFrequencyEnum).map((postFrequencyKey) => {
                            return {
                                value: postFrequencyEnum[postFrequencyKey],
                                label: Utility.capitalize(content[postFrequencyKey])
                            }
                        })}
                        onChange={(selectedResponse) => setPostFrequency(selectedResponse)}
                        placeholder={Utility.capitalize(content.SELECT_FROM_THE_LIST)}
                    />
                </div>

                <div className="button-section">
                    <StyledButton
                        brandColorOverride={eventColor}
                        type="main"
                        onClick={() => setAdminPostStage(adminPostTypeEnum.PREVIEW)}
                        isDisabled={!validateSocialMediaInputs()}
                    >
                        {Utility.capitalize(content.PREVIEW)}
                    </StyledButton>

                    <StyledButton
                        brandColorOverride={eventColor}
                        type="secondary"
                        onClick={() => startOver(true)}
                    >
                        <ArrowCounterClockwise color={eventColor || colors.BASE_RED} weight="bold" size={18}/>
                        {Utility.capitalize(content.START_OVER)}
                    </StyledButton>
                </div>
            </div>
        </div>
    );
};

export default CreateSocialMediaPost;