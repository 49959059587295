import React, {useContext, useEffect} from "react";
import "./componentStyle.scss";
import {routes, GeneralContext} from "../contexts/GeneralContext";
import AppHeader from "./AppHeader";
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import StyledUpload from "../shared-components/generic/StyledUpload";
import {IconButton} from "@mui/material";
import {DeleteForever} from "@mui/icons-material";
import StyledCheckbox from "../shared-components/generic/StyledCheckbox";
import StyledButton from "../shared-components/generic/StyledButton";
import StyledInput from "../shared-components/generic/StyledInput";
import {Link, useNavigate} from "react-router-dom";

const SharePage = () => {
    const {
        appLang,
        eventId,
        eventColor,
        commentPrompt,
        commentPlaceholder,
        userName,
        userComments,
        currentUploads,
        hasAcceptedTerms,
        setUserName,
        setUserComments,
        setHasAcceptedTerms,
        setUploadMediaModalOpen,
        handleDeleteImage,
        submitContent,
    } = useContext(GeneralContext);

    const content = language[appLang];
    const navigate = useNavigate();

    useEffect(() => {
        setUserComments("");
    }, []);

    const renderCurrentUploads = () => {
        const mediaToRender = [];
        currentUploads.forEach((uploadObj, index) => {
            mediaToRender.push(
                <div className="media-box" key={`media-upload-${index}`}>
                    {Utility.renderMedia(uploadObj.tempUrl, `Uploaded media ${index + 1}`, uploadObj.metaData.fileExt, "current-upload")}
                    <IconButton
                        style={eventColor ? {"backgroundColor": eventColor, "borderColor": eventColor} : null}
                        onClick={() => handleDeleteImage(index)}
                    >
                        <DeleteForever style={eventColor ? {"backgroundColor": eventColor, "borderColor": eventColor} : null}/>
                    </IconButton>
                </div>
            )
        });

        return mediaToRender;
    }

    const handleInitiateUpload = () => {
        setUploadMediaModalOpen(true);
    }

    const getTermsAndConditionsLabel = () => {
        const variableMap = new Map();
        const createTermsAndConditionsLink = () => {
            return (
                <Link
                    className="styled-link"
                    to={routes.TERMS_AND_CONDITIONS + "/?id=" + eventId}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span>{content.TERMS_AND_CONDITIONS}</span>
                    <div className="link-underline"/>
                </Link>
            )
        };
        variableMap.set("termsAndConditions", createTermsAndConditionsLink());
        return Utility.createVariableSentenceSpan(Utility.capitalize(content.I_ACCEPT_THE_$$TERMS_AND_CONDITIONS$$), variableMap);
    }

    const handleShareUploads = () => {
        submitContent().catch((e) => console.error(e));
        navigate(routes.VIEW + "/?id=" + eventId);
    }

    return (
        <div className="main-container share-page with-header">
            <AppHeader withSearch={false} />

            <div className="small-text-regular">
                {Utility.capitalize(content.SHARE_AND_UPLOAD_YOUR_PHOTOS_OR_VIDEOS)}:
            </div>
            <div className="image-gallery">
                {renderCurrentUploads()}
                <StyledUpload
                    brandColorOverride={eventColor}
                    onClick={handleInitiateUpload}
                />
            </div>

            <div className="input-container">
                <span className="small-text-regular">{commentPrompt
                    ? commentPrompt
                    : Utility.capitalize(content.ANYTHING_YOU_WANT_TO_SAY) + "?"
                }</span>
                <StyledInput
                    value={userComments}
                    onChange={(e) => setUserComments(e.target.value)}
                    placeholder={commentPlaceholder
                        ? commentPlaceholder
                        : Utility.capitalize(content.YOUR_THOUGHTS_OR_COMMENTS_OPTIONAL)
                    }
                />
            </div>
            <div className="input-container">
                <span className="small-text-regular">{Utility.capitalize(content.WOULD_YOU_LIKE_YOUR_NAME_TO_SHINE)}?</span>
                <StyledInput
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder={Utility.capitalize(content.YOUR_NAME_OPTIONAL)}
                />
            </div>

            <div className="bottom-section">
                <StyledCheckbox
                    brandColorOverride={eventColor}
                    className="additional-text"
                    checked={hasAcceptedTerms}
                    onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)}
                    label={getTermsAndConditionsLabel()}
                />
                <StyledButton
                    brandColorOverride={eventColor}
                    type="main"
                    onClick={handleShareUploads}
                    isDisabled={!hasAcceptedTerms || currentUploads.length < 1}
                >
                    {Utility.capitalize(content.SHARE)}
                </StyledButton>
            </div>
        </div>
    );
};

export default SharePage;