import React, {useContext} from "react";
import "../../componentStyle.scss";
import {routes, GeneralContext} from "../../../contexts/GeneralContext";
import {AdminContext} from "../../../contexts/AdminContext";
import {language} from "../../../shared-components/content/language";
import {Utility} from "../../../shared-components/Utility";
import {useNavigate} from "react-router-dom";
import EventPost from "../../ViewPageComponents/EventPost";
import enums from "../../../enums.json";
import StyledButton from "../../../shared-components/generic/StyledButton";
import {ArrowCounterClockwise, Pencil} from "@phosphor-icons/react";
import SocialMediaLinksPost from "../../ViewPageComponents/SocialMediaLinksPost";
import ShareEmailPost from "../../ViewPageComponents/ShareEmailPost";

const {colors, adminPostTypeEnum} = enums;

const PreviewPost = () => {
    const {
        eventId,
        appLang,
        userName,
        currentUploads,
        userComments,
        eventColor,
        setUserEmail,
        setContentLoading,
        submitContent,
    } = useContext(GeneralContext);
    const {
        userDetails,
        currentPostType,
        postFrequency,
        postId,
        socialMediaMap,
        uploadLink,
        setShowUploadNew,
        setAdminPostStage,
        startOver,
        isEdit,
        likes,
    } = useContext(AdminContext);
    const content = language[appLang];
    const navigate = useNavigate();

    const handleReturnToEdit = () => {
        setUserEmail("");
        setShowUploadNew(false);
        setAdminPostStage(currentPostType);
    }

    const handleStartOver = () => {
        startOver(true);
        setUserEmail("");
    }

    const handleSubmitPost = async () => {
        setContentLoading(true);
        const success = await submitContent({
            adminId: userDetails.userId,
            postId,
            postType: currentPostType,
            postFrequency,
            socialMediaMap,
            isEdit,
            likes,
            uploadLink,
        });

        setContentLoading(false);
        if (!!success) {
            setUserEmail("");
            startOver(false);
            navigate(routes.VIEW + "/?id=" + eventId);
        }
    }

    const renderPost = () => {
        switch(currentPostType) {
            case adminPostTypeEnum.MEDIA:
                const mediaUrls = [];
                currentUploads.forEach((currentUpload) => {
                    if (!currentUpload.isDeleted) {
                        mediaUrls.push({
                            url: currentUpload.downloadUrl,
                            mediaLink: uploadLink
                        });
                    }
                });

                return <EventPost
                    eventPost={{
                        isAdminPost: true,
                        mediaUrls,
                        name: userName,
                        blurb: userComments
                    }}
                    eventColor={eventColor}
                    isAdminPreview={true}
                />
            case adminPostTypeEnum.SOCIAL_MEDIA:
                return <SocialMediaLinksPost
                    isAdminPost={true}
                    isAdminPreview={true}
                    socialMediaMap={socialMediaMap}
                    blurb={userComments}
                />
            case adminPostTypeEnum.ATTENDEE_INPUT:
                return <ShareEmailPost
                    isAdminPost={true}
                    isAdminPreview={true}
                    blurb={userComments}
                />
            default:
                return null;
        }
    }

    return (
        <div className="preview-post">

            <div className="big-text">
                {Utility.capitalize(content.PREVIEW_YOUR_POST)}
            </div>

            {renderPost()}

            <div className="button-section">
                <StyledButton
                    brandColorOverride={eventColor}
                    type="main"
                    onClick={handleSubmitPost}
                >
                    {Utility.capitalize(content.POST)}
                </StyledButton>

                <StyledButton
                    brandColorOverride={eventColor}
                    type="secondary"
                    onClick={handleReturnToEdit}
                >
                    <Pencil color={eventColor || colors.BASE_RED} weight="bold" size={18}/> {Utility.capitalize(content.EDIT)}
                </StyledButton>

                <StyledButton
                    brandColorOverride={eventColor}
                    type="secondary"
                    onClick={handleStartOver}
                >
                    <ArrowCounterClockwise color={eventColor || colors.BASE_RED} weight="bold" size={18}/> {Utility.capitalize(content.START_OVER)}
                </StyledButton>
            </div>

        </div>
    );
};

export default PreviewPost;