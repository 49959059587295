import React from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";

const StyledRadioGroup = (props) => {
    const {
        className,
        radioButtonList,
        value,
        onChange,
        brandColorOverride,
    } = props;

    return (
        <div className={`styled-radio-group${className ? " " + className : ""}`}>
            {radioButtonList.map((radioButtonObject, index) => {
                const {
                    isDisabled,
                    selectionValue,
                    label,
                    renderSubContent
                } = radioButtonObject;
                const isSelected = selectionValue === value;

                return (
                    <div
                        key={selectionValue+index}
                        className="styled-radio-btn-container"
                        onClick={() => !isDisabled && onChange(selectionValue)}
                    >
                        <div className="styled-radio-btn">
                            <div
                                className={`styled-radio-circle${isSelected ? " selected" : ""}${isDisabled ? " disabled" : ""}`}
                                style={!!brandColorOverride && isSelected && !isDisabled
                                    ? {borderColor: brandColorOverride}
                                    : null
                                }
                            />
                            <div className="styled-radio-label">
                                {label}
                            </div>
                        </div>
                        {!!renderSubContent
                            ? <div className="styled-radio-sub-content">
                                {renderSubContent()}
                            </div>
                            : null
                        }
                    </div>
                )
            })}
        </div>
    );
};

export default StyledRadioGroup;