import React, {useContext} from "react";
import "../componentStyle.scss";
import {Utility} from "../../shared-components/Utility";
import {GeneralContext} from "../../contexts/GeneralContext";
import {language} from "../../shared-components/content/language";
import {QRCodeSVG} from "qrcode.react";

const DigitalWallFooter = () => {
    const {
        appLang,
        eventId,
        eventName,
        eventColor,
        sponsorUrls,
        welcomeMsg,
    } = useContext(GeneralContext);
    const content = language[appLang];

    const renderQrCode = () =>
        <QRCodeSVG
            className="qr-code"
            renderas="svg"
            value={`https://${window.location.host}/?id=${eventId}`}
            size={1024}
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="H"
        />

    return (
        <div
            className="digital-wall-footer"
            style={!!eventColor ? {
                background: eventColor,
                color: Utility.getTextColorForBackground(eventColor)
            } : null}
        >
            {sponsorUrls.length
                ? <div className="sponsor-url-container">
                    {sponsorUrls.map((sponsorUrl, index) =>
                        !!sponsorUrl && <img src={sponsorUrl} alt="sponsor url" key={index}/>
                    )}
                </div>
                : null
            }
            <div className="big-text">
                {welcomeMsg
                    ? welcomeMsg
                    : Utility.createVariableSentenceSpan(Utility.capitalize(content.WELCOME_TO_$$EVENT_NAME$$), new Map([["eventName", eventName]]))
                }
            </div>
            <div className="qr-container">
                <div className="small-text-regular">
                    {Utility.capitalize(content.CAPTURE_THE_MOMENT)}
                </div>
                {renderQrCode()}
            </div>
        </div>
    );
};

export default DigitalWallFooter;