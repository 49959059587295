import React, {useState, useContext, useEffect} from "react";
import "../componentStyle.scss";
import {GeneralContext, routes} from "../../contexts/GeneralContext";
import {language} from "../../shared-components/content/language";
import AppHeader from "../AppHeader";
import EventPost from "./EventPost";
import SocialMediaLinksPost from "./SocialMediaLinksPost";
import {Utility} from "../../shared-components/Utility";
import StyledButton from "../../shared-components/generic/StyledButton";
import {Plus, ArrowUUpLeft} from "@phosphor-icons/react";
import {useNavigate} from "react-router-dom";
import ShareEmailPost from "./ShareEmailPost";
import LoadingCard from "./LoadingCard";
import {useMediaQuery} from "react-responsive";
import {isMobile} from "react-device-detect";
import DigitalWallFooter from "./DigitalWallFooter";
import EventPostList from "./EventPostList";
import enums from "../../enums.json";
import {AdminContext} from "../../contexts/AdminContext";

const {adminPostTypeEnum} = enums;

const ViewPage = () => {
    const {
        appLang,
        eventId,
        eventColor,
        eventPostMap,
        shareEmailActive,
    } = useContext(GeneralContext);
    const {
        getUserIsAdminWithEventAccess
    } = useContext(AdminContext);
    const content = language[appLang];
    const navigate = useNavigate();
    const isLandscape = useMediaQuery({query: "(orientation: landscape)"});
    const useDigitalWallView = !isMobile && isLandscape;

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey) {
                setShowButton(true);
            }
        };

        const handleKeyUp = (event) => {
            if (!event.ctrlKey) {
                setShowButton(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    const renderPosts = () => {
        let addLoadingCard = false;
        const posts = [];

        [...eventPostMap.values()].forEach((eventPost) => {
            if (!!eventPost?.isDeleted) {
                return;
            }
            if (eventPost === "loading") {
                addLoadingCard = true;
            } else if ((eventPost?.postType === adminPostTypeEnum.SOCIAL_MEDIA) && !useDigitalWallView) {
                if (!!eventPost.socialMediaLinks?.length) {
                    const socialMediaMap = new Map();
                    eventPost.socialMediaLinks.forEach((socialMediaLinkObj) => {
                        const socialMediaName = Object.keys(socialMediaLinkObj)[0];
                        socialMediaMap.set(socialMediaName, socialMediaLinkObj[socialMediaName]);
                    })
                    const post = (
                        <SocialMediaLinksPost
                            socialMediaMap={socialMediaMap}
                            blurb={eventPost.blurb}
                            postId={eventPost.id}
                            key={eventPost.id}
                            postType={eventPost.postType}
                            postFrequency={eventPost.postFrequency}
                        />
                    );
                    posts.push(post);
                }
            } else if ((eventPost?.postType === adminPostTypeEnum.ATTENDEE_INPUT) && !useDigitalWallView) {
                const post = (
                    <ShareEmailPost
                        blurb={eventPost.blurb}
                        postId={eventPost.id}
                        key={eventPost.id}
                        postType={eventPost.postType}
                        postFrequency={eventPost.postFrequency}
                    />
                );
                posts.push(post);
            } else if ((eventPost?.postType !== adminPostTypeEnum.SOCIAL_MEDIA) && (eventPost?.postType !== adminPostTypeEnum.ATTENDEE_INPUT)) {
                const post = (
                    <EventPost
                        eventPost={eventPost} key={eventPost.id} eventColor={eventColor}
                    />
                );
                posts.push(post);
            }
        });

        if (addLoadingCard) {
            posts.unshift(<LoadingCard message={`${Utility.capitalize(content.LOADING)}...`} key={"loading-new-post"}/>)
        }

        return <EventPostList posts={posts} useDigitalWallView={useDigitalWallView}/>;
    }

    return (
        <div className={`main-container view-page${!useDigitalWallView ? " with-header" : ""}`}>
            {useDigitalWallView
                ? null
                : <AppHeader withSearch={false} isViewPage={true}/>
            }
            {renderPosts()}
            {useDigitalWallView
                ? <>
                    {getUserIsAdminWithEventAccess(eventId) && showButton
                        ? <StyledButton
                            className="floating-share-btn additional-text go-to-post"
                            type="main"
                            brandColorOverride={eventColor}
                            onClick={() => navigate(routes.ADMIN_POST + "/?id=" + eventId)}
                        >
                            <ArrowUUpLeft color="#FFFFFF" weight="bold" size={14}/> {Utility.capitalize(content.POST)}
                        </StyledButton>
                        : null
                    }
                    <DigitalWallFooter/>
                </>
                : getUserIsAdminWithEventAccess(eventId)
                    ? null
                    : <StyledButton
                        className="floating-share-btn additional-text"
                        type="main"
                        brandColorOverride={eventColor}
                        onClick={() => navigate(routes.UPLOAD + "/?id=" + eventId)}
                    >
                        <Plus color="#FFFFFF" weight="bold" size={14}/> {Utility.capitalize(content.SHARE)}
                    </StyledButton>
            }
        </div>
    );
};

export default ViewPage;