import React, {useContext} from "react";
import "../componentStyle.scss";
import instagramLogo from "../../shared-components/assets/instagram-logo.png";
import facebookLogo from "../../shared-components/assets/facebook-logo.png";
import tiktokLogo from "../../shared-components/assets/tiktok-logo.png";
import linkedInLogo from "../../shared-components/assets/linkedin-logo.png";
import youTubeLogo from "../../shared-components/assets/youtube-logo.png";
import {Utility} from "../../shared-components/Utility";
import {DotsThree, Pencil, Trash} from "@phosphor-icons/react";
import StyledDropdownMenu from "../../shared-components/generic/StyledDropdownMenu";
import {AdminContext} from "../../contexts/AdminContext";
import {GeneralContext, routes} from "../../contexts/GeneralContext";
import {language} from "../../shared-components/content/language";
import {useNavigate} from "react-router-dom";
import enums from "../../enums.json";

const {colors} = enums;

const SocialMediaLinksPost = (props) => {
    const {
        postId,
        socialMediaMap,
        blurb,
        isAdminPreview,
        postType,
        postFrequency,
    } = props;
    const {
        appLang,
        eventId,
        eventColor,
        deletePost,
    } = useContext(GeneralContext);
    const {
        setUpEditPost,
        getUserIsAdminWithEventAccess
    } = useContext(AdminContext);
    const content = language[appLang];
    const navigate = useNavigate();

    const isAdminWithEditPrivileges = getUserIsAdminWithEventAccess(eventId);

    const handleGoToEditPost = () => {
        if (!isAdminWithEditPrivileges) {
            return;
        }

        setUpEditPost({
            id: postId,
            blurb,
            postFrequency,
            postType,
            socialMediaMap
        });
        navigate(routes.ADMIN_POST + "/?id=" + eventId);
    }

    const getMenuOptions = () => {
        if (isAdminWithEditPrivileges && !isAdminPreview) {
            return [
                {
                    element: <div className="event-post-menu-option">
                        <Pencil size={18} weight="bold" color={colors.BLACK}/>
                        <span>{Utility.capitalize(content.EDIT)}</span>
                    </div>,
                    action: () => handleGoToEditPost()
                },
                {
                    element: <div className="event-post-menu-option">
                        <Trash size={18} weight="bold" color={colors.ERROR_COLOR}/>
                        <span>{Utility.capitalize(content.DELETE)}</span>
                    </div>,
                    action: () => deletePost(postId),
                    targetedClassName: "delete"
                }
            ]
        } else return [];
    }

    const renderLinks = () => {
        return (
            <div className="social-media-links">
                {!!socialMediaMap.get("instagram")?.isActive && <img src={instagramLogo} alt="Instagram" onClick={() => Utility.openInNewTab(socialMediaMap.get("instagram").link)}/>}
                {!!socialMediaMap.get("facebook")?.isActive && <img src={facebookLogo} alt="Facebook" onClick={() => Utility.openInNewTab(socialMediaMap.get("facebook").link)}/>}
                {!!socialMediaMap.get("linkedIn")?.isActive && <img src={linkedInLogo} alt="LinkedIn" onClick={() => Utility.openInNewTab(socialMediaMap.get("linkedIn").link)}/>}
                {!!socialMediaMap.get("youTube")?.isActive && <img src={youTubeLogo} alt="YouTube" onClick={() => Utility.openInNewTab(socialMediaMap.get("youTube").link)}/>}
                {!!socialMediaMap.get("tikTok")?.isActive && <img src={tiktokLogo} alt="TikTok" onClick={() => Utility.openInNewTab(socialMediaMap.get("tikTok").link)}/>}
            </div>
        )
    }

    return (
        <div className="event-post additional-text">
            <div className="post-header">
                <div>{blurb}</div>

                <div className="post-header-right">
                    <div/>
                    {!isAdminPreview && getMenuOptions()?.length
                        ? <StyledDropdownMenu
                            className="event-post-menu"
                            icon={<DotsThree color="#8E8E8E" weight="bold" size={24}/>}
                            position="left"
                            options={getMenuOptions()}
                            colors={colors}
                            brandColorOverride={eventColor}
                        />
                        : null
                    }
                </div>
            </div>
            {renderLinks()}
        </div>
    );
};

export default SocialMediaLinksPost;