import React, {useContext} from "react";
import "../componentStyle.scss";
import {Heart, DotsThree, DownloadSimple, Trash, Pencil} from "@phosphor-icons/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Utility} from "../../shared-components/Utility";
import {GeneralContext, useDigitalWallView, routes} from "../../contexts/GeneralContext";
import {AdminContext} from "../../contexts/AdminContext";
import {IconButton} from "@mui/material";
import {language} from "../../shared-components/content/language";
import StyledDropdownMenu from "../../shared-components/generic/StyledDropdownMenu";
import enums from "../../enums.json";
import {useNavigate} from "react-router-dom";

const {colors} = enums;

const EventPost = ({eventPost, eventColor, isAdminPreview}) => {
    const {
        eventId,
        addLike,
        appLang,
        userId,
        deletePost,
    } = useContext(GeneralContext);
    const {
        userDetails,
        setUpEditPost,
        getUserIsAdminWithEventAccess
    } = useContext(AdminContext);
    const content = language[appLang];
    const navigate = useNavigate();

    const isAdminWithEditPrivileges = getUserIsAdminWithEventAccess(eventId);
    const isAdminPost = eventPost?.isAdminPost;
    const posterName = eventPost.name || (
        isAdminPost
            ? Utility.capitalize(content.EVENT_ORGANIZER)
            : Utility.capitalize(content.ANONYMOUS_ATTENDEE)
    );
    const hasLikes = !!eventPost.likes?.length;

    const handleOnMediaClick = (urlObj) => {
        const url = urlObj?.mediaLink;
        if (!!url) {
            const externalUrl = /^(https?:\/\/)/.test(url) ? url : `https://${url}`;
            const newWindow = window.open(externalUrl, '_blank', 'noopener,noreferrer');
            if (newWindow) {
                newWindow.opener = null;
            }
        }
    }

    const handleGoToEditPost = () => {
        if (!eventPost?.isAdminPost || !isAdminWithEditPrivileges) {
            return;
        }

        setUpEditPost(eventPost);
        navigate(routes.ADMIN_POST + "/?id=" + eventPost.eventId);
    }

    const handleDownloadMedia = () => {
        eventPost.mediaUrls.forEach((urlObj) => {
            const downloadUrl = urlObj?.url;
            if (!!downloadUrl) {
                window.open(downloadUrl, '_blank');
            }
        })
    }

    const getMenuOptions = () => {
        const menuOptions = [{
            element: <div className="event-post-menu-option">
                <DownloadSimple size={18} weight="bold" color={colors.BLACK}/>
                <span>{Utility.capitalize(content.DOWNLOAD)}</span>
            </div>,
            action: () => handleDownloadMedia()
        }];

        if (isAdminWithEditPrivileges && isAdminPost) {
            menuOptions.push({
                element: <div className="event-post-menu-option">
                    <Pencil size={18} weight="bold" color={colors.BLACK}/>
                    <span>{Utility.capitalize(content.EDIT)}</span>
                </div>,
                action: () => handleGoToEditPost()
            });
        }

        if (isAdminWithEditPrivileges || userId === eventPost.userId) {
            menuOptions.push({
                element: <div className="event-post-menu-option">
                    <Trash size={18} weight="bold" color={colors.ERROR_COLOR}/>
                    <span>{Utility.capitalize(content.DELETE)}</span>
                </div>,
                action: () => deletePost(eventPost.id, userDetails?.userId),
                targetedClassName: "delete"
            });
        }

        return menuOptions;
    }

    const renderMedia = () => {
        const sliderSettings = {
            dots: false,
            arrows: false,
            infinite: eventPost.mediaUrls.length > 1,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            slidesToShow: 1,
            slidesToScroll: 1,
        }

        return <Slider {...sliderSettings}>
            {eventPost.mediaUrls.map((urlObj, index) => {
                const mediaUrl = useDigitalWallView ? urlObj?.url : urlObj?.thumbnailUrl || urlObj?.url;
                return (
                    <div
                        className="media-container"
                        key={`post-media-${index}`}
                        onClick={() => handleOnMediaClick(urlObj)}
                    >
                        {Utility.renderMedia(mediaUrl || "", `Media number ${index + 1} for ${posterName}'s post.`, null, "rendered-media")}
                    </div>
                )
            })}
        </Slider>
    }

    return (
        <div className="event-post additional-text">
            <div className="post-header">
                <div>{posterName}</div>

                <div className="post-header-right">
                    {getMenuOptions()?.length
                        ? null
                        : <div/>
                    }
                    {!isAdminPreview
                        ? <div className={`like-icon${hasLikes ? " has-likes" : ""}`}>
                            <span>{hasLikes ? eventPost.likes.length : ""}</span>
                            <IconButton onClick={() => addLike(eventPost.id)}>
                                <Heart size={24} color={hasLikes ? eventColor : colors.BORDER_COLOR}
                                       weight={hasLikes ? "fill" : "bold"}/>
                            </IconButton>
                        </div>
                        : <div/>
                    }
                    {isAdminPreview
                        ? <div/>
                        : <StyledDropdownMenu
                            className="event-post-menu"
                            icon={<DotsThree color="#8E8E8E" weight="bold" size={24}/>}
                            position="left"
                            options={getMenuOptions()}
                            colors={colors}
                            brandColorOverride={eventColor}
                        />
                    }
                </div>
            </div>

            {renderMedia()}

            {eventPost.blurb
                ? <div className="post-blurb">
                    {eventPost.blurb}
                </div>
                : null
            }
        </div>
    );
};

export default EventPost;