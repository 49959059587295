import React, {useContext, useEffect, useState} from "react";
import "../componentStyle.scss";
import {routes, GeneralContext} from "../../contexts/GeneralContext";
import {AdminContext} from "../../contexts/AdminContext";
import {language} from "../../shared-components/content/language";
import StyledButton from "../../shared-components/generic/StyledButton";
import {Utility} from "../../shared-components/Utility";
import {useNavigate} from 'react-router-dom';
import AppHeader from "../AppHeader";
import enums from "../../enums.json";
import CreateMediaPost from "./AdminPosts/CreateMediaPost";
import CreateSocialMediaPost from "./AdminPosts/CreateSocialMediaPost";
import CreateAttendeeInputPost from "./AdminPosts/CreateAttendeeInputPost";
import PreviewPost from "./AdminPosts/PreviewPost";
import StyledRadioGroup from "../../shared-components/generic/StyledRadioGroup";
import genericMedia from "../../assets/generic-media.png";
import genericSocialMediaLinks from "../../assets/generic-social-media-links.png";
import genericInput from "../../assets/generic-input.png";

const {adminPostTypeEnum} = enums;

const AdminPost = () => {
    const {
        appLang,
        eventId,
        eventColor,
    } = useContext(GeneralContext);
    const {
        signOut,
        isUserLoggedIn,
        userDetails,
        isUserLoaded,
        adminPostStage,
        setAdminPostStage,
        getUserIsAdminWithEventAccess,
    } = useContext(AdminContext);
    const content = language[appLang];
    const navigate = useNavigate();

    const [postTypeSelection, setPostTypeSelection] = useState(adminPostTypeEnum.MEDIA);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const eventIdFromParams = urlSearchParams.get('id');
        const eventToSearch = eventId || eventIdFromParams;

        if (!!isUserLoaded && !getUserIsAdminWithEventAccess(eventToSearch)) {
            navigate(routes.ROOT + "?id=" + (eventToSearch))
        }
    }, [isUserLoaded, eventId, isUserLoggedIn, userDetails]);

    const renderGenericImage = (src, alt) => {
        return (
            <div className="generic-image">
                <img
                    src={src}
                    alt={alt}
                    style={src === genericMedia
                        ? {maxHeight: 150}
                        : null
                    }
                />
            </div>
        )
    }

    const radioButtonList = [
        {
            selectionValue: adminPostTypeEnum.MEDIA,
            label: Utility.capitalize(content.MEDIA_PLUS_CAPTION_PLUS_LINK),
            renderSubContent: () => renderGenericImage(genericMedia, Utility.capitalize(content.GENERIC_IMAGE_OF_MEDIA))
        },
        {
            selectionValue: adminPostTypeEnum.SOCIAL_MEDIA,
            label: Utility.capitalize(content.SOCIAL_MEDIA_LINKS),
            renderSubContent: () => renderGenericImage(genericSocialMediaLinks, Utility.capitalize(content.GENERIC_IMAGE_OF_SOCIAL_MEDIA_LINKS))
        },
        // {
        //     selectionValue: adminPostTypeEnum.ATTENDEE_INPUT,
        //     label: Utility.capitalize(content.ATTENDEE_INPUT_COLLECTION),
        //     renderSubContent: () => renderGenericImage(genericInput, Utility.capitalize(content.GENERIC_IMAGE_OF_AN_INPUT_BOX))
        // },
    ];

    const renderSelectPostType = () => {
        return (
            <div className="select-post-type">
                <div className="big-text">{Utility.capitalize(content.FIRST_CHOOSE_YOUR_POST_FORMAT)}</div>
                <StyledRadioGroup
                    className="small-text"
                    brandColorOverride={eventColor}
                    value={postTypeSelection}
                    onChange={(selection) => setPostTypeSelection(selection)}
                    radioButtonList={radioButtonList}
                />
                <StyledButton
                    brandColorOverride={eventColor}
                    type="main"
                    onClick={() => setAdminPostStage(postTypeSelection)}
                >
                    {Utility.capitalize(content.NEXT)}
                </StyledButton>
            </div>
        )
    }

    const renderAdminPostSelection = () => {
        switch(adminPostStage) {
            case adminPostTypeEnum.MEDIA:
                return <CreateMediaPost/>
            case adminPostTypeEnum.SOCIAL_MEDIA:
                return <CreateSocialMediaPost/>
            case adminPostTypeEnum.ATTENDEE_INPUT:
                return <CreateAttendeeInputPost/>
            case adminPostTypeEnum.PREVIEW:
                return <PreviewPost/>
            case adminPostTypeEnum.SELECT:
            default:
                return renderSelectPostType();
        }
    }

    return (
        <div className="main-container admin-post with-header">
            <AppHeader withSearch={false}/>

            {renderAdminPostSelection()}

            <StyledButton
                type="text"
                brandColorOverride={eventColor}
                onClick={signOut}
            >
                {Utility.capitalize(content.LOG_OUT)}
            </StyledButton>
        </div>
    );
};

export default AdminPost;