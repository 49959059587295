import React, {useContext} from "react";
import "./componentStyle.scss";
import {routes, GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import StyledButton from "../shared-components/generic/StyledButton";
import {Utility} from "../shared-components/Utility";
import {useNavigate} from 'react-router-dom';
import CoverImageViewer from "./CoverImageViewer";
import {AdminContext} from "../contexts/AdminContext";
import ClipLoader from "react-spinners/ClipLoader";
import enums from "../enums.json";

const {colors} = enums;

const LandingPage = () => {
    const {
        appLang,
        eventId,
        eventName,
        eventColor,
        welcomeMsg,
    } = useContext(GeneralContext);
    const {
        getUserIsAdminWithEventAccess
    } = useContext(AdminContext);
    const content = language[appLang];
    const navigate = useNavigate();

    const handleAdminLogin = () => {
        if (getUserIsAdminWithEventAccess(eventId)) {
            navigate(routes.ADMIN_POST + "/?id=" + eventId);
        } else {
            navigate(routes.ADMIN_LOGIN + "/?id=" + eventId);
        }
    }

    return (
            <div className="landing-page">
                {eventId
                    ? <>
                        <CoverImageViewer/>
                        <div className="main-container">
                            <div className="big-text">
                                {welcomeMsg
                                    ? welcomeMsg
                                    : Utility.createVariableSentenceSpan(Utility.capitalize(content.WELCOME_TO_$$EVENT_NAME$$), new Map([["eventName", eventName]]))
                                }
                            </div>
                            <div className="small-text">
                                <div>{Utility.capitalize(content.CAPTURE_AND_SHARE_YOUR_MOMENT)}</div>
                                <div>{Utility.capitalize(content.VIEW_OTHERS_MOMENTS_IN_A_LIVE_FEED)}</div>
                                {/*<div>{Utility.capitalize(content.ENTER_A_RAFFLE_TO_WIN_SWAG)}</div>*/}
                            </div>
                            <div className="bottom-section">
                                <StyledButton
                                    brandColorOverride={eventColor}
                                    type="main"
                                    onClick={() => navigate(routes[getUserIsAdminWithEventAccess(eventId) ? "ADMIN_POST" : "UPLOAD"] + "/?id=" + eventId)}
                                >
                                    {Utility.capitalize(content.START_SHARING)}
                                </StyledButton>
                                <StyledButton
                                    type="text"
                                    brandColorOverride={eventColor}
                                    onClick={handleAdminLogin}
                                >
                                    {Utility.capitalize(content.ADMIN_LOGIN)}
                                </StyledButton>
                            </div>
                        </div>
                    </>
                    : <div className="spinner">
                        <ClipLoader
                            loading={true}
                            size={100}
                            color={eventColor || colors.BASE_RED}
                        />
                    </div>
                }
            </div>
    );
};

export default LandingPage;