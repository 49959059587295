import React, {createContext, useEffect, useState} from "react";
import enums from '../enums.json';
import {language} from "../shared-components/content/language";
import {Utility} from "../shared-components/Utility";
import {isMobile} from "react-device-detect";

const pollInterval = 1000 * 15;     //15 seconds
const activityDropOff = 1000 * 120;  //2 minutes

const {languages, colors, batchEnum, adminPostTypeEnum, postFrequencyEnum} = enums;

export const routes = {
    ROOT: "/",
    UPLOAD: "/upload",
    VIEW: "/view",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
    ADMIN_LOGIN: "/admin-login",
    ADMIN_POST: "/admin-post",
    SIGN_IN_REDIRECT: "/sign-in-redirect",
    WILDCARD: "/*",
    NOT_FOUND: "/404"
}

export const useDigitalWallView = !isMobile;
const batchSize = useDigitalWallView ? batchEnum?.WALL_SIZE || 60 : batchEnum?.FEED_SIZE || 20;

export const GeneralContext = createContext(null);

export const GeneralProvider = (props) => {
    const [contentLoading, setContentLoading] = useState(true);
    const [postLoading, setPostLoading] = useState(false);
    const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
    const [appLang, setAppLang] = useState(languages.ENGLISH);
    const content = language[appLang];
    const [currentUploads, setCurrentUploads] = useState([]);
    const [objectUrls, setObjectUrls] = useState([]);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(true);
    const [userId, setUserId] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [userComments, setUserComments] = useState("");
    const [eventIsActive, setEventIsActive] = useState(true);
    const [eventName, setEventName] = useState("");
    const [eventColor, setEventColor] = useState(colors.BASE_RED);
    const [eventId, setEventId] = useState("");
    const [firstPostId, setFirstPostId] = useState("");
    const [eventDates, setEventDates] = useState({
        startDts: new Date(),
        endDts: new Date(Date.now() + 1000 * 60 * 60 * 24),
        filterActive: false
    });
    const [shareEmailActive, setShareEmailActive] = useState(false);
    const [socialMedia, setSocialMedia] = useState({});
    const [mainCoverImage, setMainCoverImage] = useState("");
    const [coverImages, setCoverImages] = useState([]);
    const [sponsorUrls, setSponsorUrls] = useState([]);
    const [commentPlaceholder, setCommentPlaceholder] = useState("");
    const [commentPrompt, setCommentPrompt] = useState("");
    const [welcomeMsg, setWelcomeMsg] = useState("");
    const [eventPostMap, setEventPostMap] = useState(new Map([["loading", "loading"]]));
    const [batchEndDetails, setBatchEndDetails] = useState({postDate: new Date()});
    const [blockFetchPosts, setBlockFetchPosts] = useState(false);
    const [pollTimer, setPollTimer] = useState(setTimeout(() => {
    }, 100));
    const [pollState, setPollState] = useState(Date.now());
    const [pollId, setPollId] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [lastActiveMillis, setLastActiveMillis] = useState(Date.now());
    const [lastUpdateDts, setLastUpdateDts] = useState(new Date());

    useEffect(() => {
        const existingId = localStorage.getItem("scale-social-events-id");
        if (existingId) {
            setUserId(existingId);
        } else {
            const newUserId = Utility.makeId(20);
            localStorage.setItem("scale-social-events-id", newUserId);
            setUserId(newUserId);
        }
        setUserId(existingId || Utility.makeId(20));

        const newPollTimer = setInterval(() => {
            setPollState(Date.now());
        }, pollInterval);
        setPollTimer(newPollTimer);

        return () => clearTimeout(pollTimer);
    }, []);

    const handleUserActivity = () => {
        setLastActiveMillis(Date.now());
    }

    useEffect(() => {
        const events = [
            'mousemove',
            'mousedown',
            'keydown',
            'scroll',
            'touchstart',
            'touchmove',
            'resize'
        ];

        events.forEach((event) => window.addEventListener(event, handleUserActivity));

        return () => {
            events.forEach((event) => window.removeEventListener(event, handleUserActivity));
        };
    }, [lastActiveMillis]);

    useEffect(() => {
        if (!!eventId && !!pollId) {
            if (blockFetchPosts) {
                getEventPostUpdates().then();
            } else {
                getEventPosts(eventId, pollId, null).then();
            }
        }
    }, [pollState]);

    const switchLanguage = language => {
        setAppLang(language);
        // setLangDir(language === languages.HEBREW || language === languages.ARABIC ? "rtl" : "ltr")   //save to use if needed for rtl languages
    };

    const getUserIsActive = () => lastActiveMillis > Date.now() - activityDropOff;

    const uploadMedia = async (files) => {
        const newUploads = [];
        const updatedObjectUrls = [...objectUrls];
        const saveMediaToStoragePromises = [];

        files.forEach((file) => {
            if (file.size > 25000000) {
                setErrorMsg(Utility.capitalize(content.FILE_TOO_LARGE))
                return;
            }

            const lastModifiedDate = new Date(file.lastModified || file.lastModifiedDate || 0);
            if (
                eventDates.filterActive
                && (
                    new Date(eventDates.startDts) > lastModifiedDate
                    || new Date(eventDates.endDts) < lastModifiedDate
                )
            ) {
                setErrorMsg(Utility.capitalize(content.PLEASE_USE_CURRENT_MEDIA))
                return;
            }

            const metaData = {
                type: file.type,
                size: file.size,
                fileExt: file.name.split(".").pop().toLowerCase(),
            }
            const tempUrl = URL.createObjectURL(file);

            const tempUpload = {
                metaData,
                tempUrl
            }

            const rootPath = `events/${eventId}`;
            saveMediaToStoragePromises.push(Utility.saveMediaToStorage(file, metaData, rootPath, eventName));
            newUploads.push(tempUpload);
            updatedObjectUrls.push(tempUrl);
        });

        setContentLoading(true);
        const saveMediaToStorageResults = await Promise.all(saveMediaToStoragePromises);
        setContentLoading(false);
        const updatedCurrentUploads = [...currentUploads];

        newUploads.forEach((updatedUpload, index) => {
            const uploadId = saveMediaToStorageResults[index]?.uploadId;
            const filePath = saveMediaToStorageResults[index]?.filePath;

            if (!!uploadId && !!filePath) {
                updatedUpload.metaData = saveMediaToStorageResults[index].metaData || updatedUpload.metaData;
                updatedUpload.downloadUrl = saveMediaToStorageResults[index].downloadUrl;
                updatedCurrentUploads.push({
                    ...updatedUpload,
                    uploadId,
                    filePath,
                });
            } else {
                setErrorMsg(content.IMAGE_UPLOAD_ERROR_SENTENCE)
            }
        });

        setCurrentUploads(updatedCurrentUploads);
        setObjectUrls(updatedObjectUrls);
    }

    const uploadBlob = async (blob, fileExt) => {
        if (blob.size > 25000000) {
            setErrorMsg(Utility.capitalize(content.FILE_TOO_LARGE))
            return;
        }

        const updatedObjectUrls = [...objectUrls];

        const metaData = {
            type: blob.type,
            size: blob.size,
            fileExt,
        }
        const tempUrl = URL.createObjectURL(blob);

        const rootPath = `events/${eventId}`;
        setContentLoading(true);
        const saveMediaToStorageResult = await Utility.saveMediaToStorage(blob, metaData, rootPath, eventName);
        setContentLoading(false);

        const uploadId = saveMediaToStorageResult?.uploadId;
        const filePath = saveMediaToStorageResult?.filePath;

        if (!!uploadId && filePath) {
            const tempUpload = {
                metaData: saveMediaToStorageResult.metaData || metaData,
                tempUrl,
                uploadId,
                filePath,
                downloadUrl: saveMediaToStorageResult?.downloadUrl
            }

            setCurrentUploads([...currentUploads, tempUpload]);
        } else {
            setErrorMsg(content.IMAGE_UPLOAD_ERROR_SENTENCE)
        }

        setObjectUrls(updatedObjectUrls);
    }

    const clearTempMedia = () => {
        const updatedUploads = [];
        currentUploads.forEach((upload) => {
            if (!!upload.tempUrl) {
                URL.revokeObjectURL(upload.tempUrl);
            } else updatedUploads.push(upload);
        });

        setCurrentUploads(updatedUploads);
    }

    const handleDeleteImage = (index, isFromAdminEdit = false) => {
        const updatedCurrentUploads = [...currentUploads];
        const imageToDelete = updatedCurrentUploads[index];

        if (isFromAdminEdit) {
            imageToDelete.isDeleted = true;
        } else {
            const urlToRevoke = imageToDelete.tempUrl;
            Utility.deleteMediaFromStorage(imageToDelete.filePath).catch((e) => console.error(e));

            updatedCurrentUploads.splice(index, 1);
            URL.revokeObjectURL(urlToRevoke);
        }

        setCurrentUploads(updatedCurrentUploads);
    }

    const deleteAllUploads = () => {
        currentUploads.forEach((currentUpload) => {
            const urlToRevoke = currentUpload.tempUrl;
            URL.revokeObjectURL(urlToRevoke);

            Utility.deleteMediaFromStorage(currentUpload.filePath).catch((e) => console.error(e));
        });

        setCurrentUploads([]);
    }

    const submitContent = async (adminPostDetails = null) => {
        const updatedEventPostMap = new Map(eventPostMap);
        updatedEventPostMap.set("loading", "loading");
        setEventPostMap(updatedEventPostMap);
        setPostLoading(!adminPostDetails);
        Utility.sleep(100).then(() => window.scrollTo(0, 1));

        let contentData;

        if (!adminPostDetails || adminPostDetails.postType === adminPostTypeEnum.MEDIA)
        contentData = currentUploads.map((upload) => {
            const modifiedUpload = {...upload};
            delete modifiedUpload.tempUrl;

            if (!!adminPostDetails?.uploadLink) {
                modifiedUpload.mediaLink = adminPostDetails?.uploadLink;
            }

            return modifiedUpload;
        });

        const postId = adminPostDetails?.postId || Utility.makeId(20);

        let success;
        try {
            const params = {
                eventId,
                userId: adminPostDetails?.adminId || userId,
                userComments,
                postId,
                batchSize,
            }

            if (!adminPostDetails || adminPostDetails.postType === adminPostTypeEnum.MEDIA) {
                params.contentData = contentData;
                params.userName = userName;
            } else if (adminPostDetails.postType === adminPostTypeEnum.SOCIAL_MEDIA) {
                const socialMediaLinks = [];
                adminPostDetails.socialMediaMap.forEach((socialMediaObj, socialMediaName) => {
                    socialMediaLinks.push({[socialMediaName]: socialMediaObj});
                });
                params.socialMediaLinks = socialMediaLinks;
            }

            if (!!adminPostDetails) {
                params.postType = adminPostDetails.postType;
                params.postFrequency = adminPostDetails.postFrequency;
                params.isEdit = adminPostDetails.isEdit;
                if (params.isEdit) {
                    params.likes = adminPostDetails.likes;
                }
                params.isAdminPost = true;
            } else {
                params.isAdminPost = false;
            }

            const response = await Utility.httpCall("submitEventContent", params);


            if (!!response?.data) {
                clearTempMedia();
                if (!!response.data.eventPosts) {
                    processEventPostResponse(response, pollId, null);
                    success = true;
                } else {
                    getEventPosts(eventId, pollId, null).catch((e) => {
                        console.error(e);
                        const updatedEventPostMap = new Map(eventPostMap);
                        updatedEventPostMap.delete("loading");
                        setEventPostMap(updatedEventPostMap);
                        success = false;
                    });
                }
            } else {
                const updatedEventPostMap = new Map(eventPostMap);
                updatedEventPostMap.delete("loading");
                setEventPostMap(updatedEventPostMap);
                setErrorMsg(Utility.capitalize(content.THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE));
                success = false;
            }

            setPostLoading(false);
        } catch (e) {
            console.error(e);
            const updatedEventPostMap = new Map(eventPostMap);
            updatedEventPostMap.delete("loading");
            setEventPostMap(updatedEventPostMap);
            setErrorMsg(Utility.capitalize(content.THERE_WAS_A_PROBLEM_UPLOADING_YOUR_CONTENT_SENTENCE));
            setPostLoading(false);
            success = false;
        }

        return success;
    }

    const getEventData = async (id) => {
        if (!id) {
            setEventIsActive(false);
            return;
        }

        try {
            const response = await Utility.httpCall("getEventProps", {
                eventId: id,
                props: [
                    "eventIsActive",
                    "eventName",
                    "eventColor",
                    "eventCoverImg",
                    "eventSocialMediaAccounts",
                    "eventDates",
                    "promotionalActive",
                    "firstPostId",
                    "sponsorImageUrls",
                    "customCommentPlaceholder",
                    "customCommentPrompt",
                    "customWelcomeMsg",
                ]
            });

            if (!!response?.data) {
                const {
                    eventIsActive: isActive,
                    eventName: name,
                    eventSocialMediaAccounts: socialMediaAccounts,
                    eventColor: color,
                    eventCoverImg,
                    eventDates,
                    promotionalActive,
                    firstPostId: initialPostId,
                    sponsorImageUrls,
                    customCommentPlaceholder,
                    customCommentPrompt,
                    customWelcomeMsg,
                } = response.data;

                setEventIsActive(!!isActive);
                setEventName(name);
                setEventColor(color);
                setMainCoverImage(eventCoverImg);
                setSocialMedia(socialMediaAccounts);
                setEventId(id);
                setEventDates(eventDates);
                setShareEmailActive(promotionalActive);
                setFirstPostId(initialPostId || "");
                setSponsorUrls(sponsorImageUrls);
                setWelcomeMsg(customWelcomeMsg || "");
                setCommentPrompt(customCommentPrompt || "");
                setCommentPlaceholder(customCommentPlaceholder || "");

                getEventPosts(id, null, null).then();
            } else console.error("No response data returned from function call");
        } catch (e) {
            console.error(e);
        }
    }

    const updateEventImages = (postList) => {
        const updatedEventImages = [];
        postList.forEach((eventPost) => {
            if (
                (!eventPost.postType || eventPost.postType === adminPostTypeEnum.MEDIA)
                && (!eventPost.postFrequency || eventPost.postFrequency <= postFrequencyEnum.ONCE_AS_NORMAL_POSTS)
            ) {
                updatedEventImages.push(...eventPost.mediaUrls);
            }
        });
        if (!!updatedEventImages.length) {
            setCoverImages(updatedEventImages);
        }
    }

    const processEventPostResponse = (response, dataPollId, batchLastItem) => {
        const fetchedPosts = response.data.eventPosts;
        const processedPollId = response.data.pollId || dataPollId;
        const processedBatchEndDetails = response.data.batchEndDetails || batchEndDetails;
        const updatedEventPostMap = new Map(!batchLastItem || batchLastItem.isRefresh ? [] : eventPostMap);

        fetchedPosts.forEach((eventPost) => {
            updatedEventPostMap.set(eventPost.id, eventPost);
        })

        updateEventImages(fetchedPosts);

        if (postLoading) {
            updatedEventPostMap.set("loading", "loading");
        }

        setEventPostMap(updatedEventPostMap);
        setPollId(processedPollId);
        setBatchEndDetails(processedBatchEndDetails);
        setLastUpdateDts(new Date());
        return processedBatchEndDetails;
    }

    const getEventPosts = async (id, dataPollId, batchLastItem) => {
        if (!useDigitalWallView && !getUserIsActive()) {
            console.log("User inactive");
        } else {
            try {
                const response = await Utility.httpCall("getEventPosts", {
                    eventId: id,
                    batchSize,
                    batchLastItem,  //if null only get the top batch-size posts
                    pollId: dataPollId,
                });

                if (!!response.data) {
                    if (response.data.noNewEvents) {
                        console.log("No new events found.");
                    } else {
                        return processEventPostResponse(response, dataPollId, batchLastItem);
                    }
                } else console.error("No response data returned from function call");
            } catch (e) {
                console.error(e);
            }
        }

        return batchLastItem;
    }

    const getNextPostBatch = async () => {
        if (!batchEndDetails) {
            return
        }
        console.log("getting next batch of posts")
        return await getEventPosts(eventId, "force-get-posts", batchEndDetails);
    }

    const updateEventPostMap = (eventMapToUpdate, updatedPostId, updatedEventPost, nonRecurringCondition = true) => {
        const updatedPostIsRecurring = updatedEventPost.postFrequency > postFrequencyEnum.ONCE_AS_NORMAL_POSTS;
        if (updatedPostIsRecurring) {
            [...eventMapToUpdate.keys()].forEach((postKey) => {
                if (Utility.getOriginalEventPostId(postKey) === Utility.getOriginalEventPostId(updatedPostId)) {
                    eventMapToUpdate.set(postKey, {...updatedEventPost, id: postKey});
                }
            });
        } else {
            if (nonRecurringCondition) {
                eventMapToUpdate.set(updatedPostId, updatedEventPost);
            }
        }
    }

    const getEventPostUpdates = async () => {
        if (!useDigitalWallView && !getUserIsActive()) {
            console.log("User inactive");
            return;
        }

        try {
            const response = await Utility.httpCall("getEventPostUpdates", {
                eventId,
                pollId,
                batchLastItem: batchEndDetails,
                lastUpdateDts,
            });

            if (!!response.data) {
                if (response.data.noUpdates) {
                    console.log("No new event post updates.");
                } else {
                    const eventPostUpdates = response.data.eventPostUpdates;
                    if (!!eventPostUpdates?.length) {
                        const updatedEventPostMap = new Map(eventPostMap);
                        eventPostUpdates.forEach((eventPostUpdate) => {
                            const updatedPostId = eventPostUpdate.id;
                            const postToUpdateIsCurrentlyInView = updatedEventPostMap.has(updatedPostId);
                            updateEventPostMap(updatedEventPostMap, updatedPostId, eventPostUpdate, postToUpdateIsCurrentlyInView);
                        });
                        setEventPostMap(updatedEventPostMap);
                        setLastUpdateDts(new Date());
                    } else console.log("No new event post updates.");
                }
            } else console.error("No response data returned from function call");
        } catch (e) {
            console.error(e);
        }
    }

    const saveUserEmail = async (email) => {
        try {
            const response = await Utility.httpCall("saveEventsUserEmail", {
                userEmail: email,
                userId,
                eventId,
            });

            if (!!response?.data) {
                const userIdFromDb = response.data.id;
                setUserId(userIdFromDb || userId);
                localStorage.setItem("scale-social-events-id", userIdFromDb);
            } else console.error("No response data returned from function call");
        } catch (e) {
            console.error(e);
        }
    }

    const updateEventPost = async (postId, propToUpdate, updatedValue) => {
        const updatedPost = eventPostMap.get(postId);
        const oldPostState = {...updatedPost};

        updatedPost[propToUpdate] = updatedValue;
        const updatedEventPostMap = new Map(eventPostMap);
        updateEventPostMap(updatedEventPostMap, postId, updatedPost);
        setEventPostMap(updatedEventPostMap);

        let success = false;
        try {
            const response = await Utility.httpCall("updateEventPost", {
                eventId,
                postId: Utility.getOriginalEventPostId(postId),
                propToUpdate,
                updatedValue,
            });

            success = !!response?.data;
        } catch (e) {
            console.error(e);
            success = false;
        }

        if (!success) {
            const revertedPostMap = new Map(eventPostMap);
            updateEventPostMap(revertedPostMap, postId, oldPostState)
            setEventPostMap(revertedPostMap);
        }
    }

    const addLike = async (postId) => {
        const postToUpdate = eventPostMap.get(postId);
        const postLikes = !!postToUpdate.likes?.length ? postToUpdate.likes : [];
        const userAlreadyLikedPostIndex = postLikes.findIndex((likeId) => likeId === userId);

        if (userAlreadyLikedPostIndex < 0) {
            postLikes.push(userId);
        } else postLikes.splice(userAlreadyLikedPostIndex, 1);

        await updateEventPost(postId, "likes", postLikes);
    }

    const deletePost = async (postId, loggedInUserId) => {
        const originalPostId = Utility.getOriginalEventPostId(postId);
        if (originalPostId === firstPostId) {
            return;
        }

        const updatedEventPostMap = new Map(eventPostMap);
        const postToDelete = {...updatedEventPostMap.get(postId)};

        updatedEventPostMap.delete(postId);
        updatedEventPostMap.delete(originalPostId);
        updateEventImages([...updatedEventPostMap.values()]);
        updateEventPostMap(updatedEventPostMap, postId, {...postToDelete, isDeleted: true})
        setEventPostMap(updatedEventPostMap);

        const response = await Utility.httpCall("deleteEventPost", {
            postId: originalPostId,
            userId: loggedInUserId || userId,
        });
        if (!response?.data) {
            updateEventPostMap(updatedEventPostMap, postId, {...postToDelete, isDeleted: false});
            setEventPostMap(updatedEventPostMap);
            updateEventImages([...updatedEventPostMap.values()]);
            setErrorMsg(Utility.capitalize(content.PROBLEM_DELETING_POST_SENTENCE));
        }
    }

    return (
        <GeneralContext.Provider value={{
            contentLoading,
            uploadMediaModalOpen,
            appLang,
            eventId,
            currentUploads,
            hasAcceptedTerms,
            userId,
            userEmail,
            userName,
            userComments,
            eventIsActive,
            socialMedia,
            eventName,
            eventColor,
            mainCoverImage,
            coverImages,
            welcomeMsg,
            commentPrompt,
            commentPlaceholder,
            eventPostMap,
            errorMsg,
            batchEndDetails,
            pollId,
            postLoading,
            shareEmailActive,
            firstPostId,
            sponsorUrls,

            setContentLoading,
            setUploadMediaModalOpen,
            setAppLang,
            setCurrentUploads,
            setHasAcceptedTerms,
            setUserEmail,
            setUserName,
            setUserComments,
            setBlockFetchPosts,
            setErrorMsg,

            switchLanguage,
            uploadMedia,
            uploadBlob,
            handleDeleteImage,
            submitContent,
            getEventData,
            saveUserEmail,
            clearTempMedia,
            getNextPostBatch,
            getEventPosts,
            addLike,
            deletePost,
            deleteAllUploads,
        }}>
            {props.children}
        </GeneralContext.Provider>
    )
}