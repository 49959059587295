import React, {useContext, useEffect, useState} from "react";
import "./componentStyle.scss";
import {GeneralContext} from "../contexts/GeneralContext";
import {Utility} from "../shared-components/Utility";

const CoverImageViewer = () => {
    const {
        mainCoverImage,
        coverImages,
        eventColor
    } = useContext(GeneralContext);

    const [currentIndex, setCurrentIndex] = useState(0);

    const imageArray = [mainCoverImage, ...coverImages];

    useEffect(() => {
        const cycleImages = () => {
            let delay = currentIndex === 0 ? 3000 : 2000;

            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
            }, delay);
        };

        cycleImages();

        return () => clearInterval(cycleImages);
    }, [currentIndex, coverImages]);


    return Utility.renderMedia(
        imageArray[currentIndex]?.thumbnailUrl || imageArray[currentIndex]?.url || imageArray[currentIndex],
        "Slideshow media",
        null,
        "event-cover-container",
        eventColor,
    );
};

export default CoverImageViewer;