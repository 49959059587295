import React, { useState, useRef, useEffect } from "react";
import "../../appStyle.scss";
import "./genericStyle.scss";

const StyledDropdownMenu = (props) => {
    const {
        className,
        options = [],
        icon,
        position,
        colors,
        brandColorOverride
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleOptionClick = (action) => {
        action();
        setIsOpen(false);
    };

    const hoverBackgroundColor = brandColorOverride || colors.BASE_RED;
    const hoverTextColor = colors.WHITE;

    return (
        <div
            ref={menuRef}
            className={`styled-dropdown-menu${className ? " " + className : ""}`}
        >
            <button
                className="menu-button"
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
            >
                {icon}
            </button>

            {isOpen && (
                <div className={`menu-dropdown ${position || "center"}`}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`menu-option ${option.targetedClassName}`}
                            onClick={() => handleOptionClick(option.action)}
                            style={{
                                "--hover-bg": hoverBackgroundColor,
                                "--hover-text": hoverTextColor
                            }}
                        >
                            {option.element}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default StyledDropdownMenu;