import './appStyle.scss';
import {Box} from "@mui/material";
import {Suspense, useContext, useEffect} from "react";
import {GeneralContext} from "./contexts/GeneralContext";
import AppRouter from "./components/AppRouter";
import {ConstantLoaderModal} from "./components/modals/LoaderModal";
import ModalContainer from "./components/modals/ModalContainer";
import {AdminProvider} from "./contexts/AdminContext";
import NotFound from "./components/NotFound";

function App() {
    const {
        eventIsActive,
        setContentLoading,
        setCurrentUploads,
        setUserComments,
        setUserName,
        clearTempMedia,
        deleteAllUploads,
        getEventData,
    } = useContext(GeneralContext);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const eventId = urlSearchParams.get('id');

    useEffect(() => {
        setContentLoading(true);
        getEventData(eventId).then(() => {
            setContentLoading(false);
        });
        return () => clearTempMedia();
    }, []);

    return (
        <AdminProvider
            setContentLoading={setContentLoading}
            setCurrentUploads={setCurrentUploads}
            setUserComments={setUserComments}
            setUserName={setUserName}
            clearTempMedia={clearTempMedia}
            deleteAllUploads={deleteAllUploads}
        >
            <div className="app small-text-regular">
                {eventId
                    ? <Box className="app-container">
                        <AppRouter eventIsActive={eventIsActive}/>
                    </Box>
                    : <NotFound/>
                }
                <Suspense fallback={ConstantLoaderModal}>
                    <ModalContainer/>
                </Suspense>
            </div>
        </AdminProvider>
    );
}

export default App;
